import {Component, Host, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {UsersService} from "../../core/services/users.service";
import {
  AdminCorporateDetailsDto,
  AdminPersonDetails, AdminStaffDetail,
  AirbnbAccount,
  Beds24Account, HostifyAccount, IcneaAccount, SmoobuAccount, SuperhoteAccount
} from "../../model/AdminCorporateDetailsDto";
import {UtilService} from "../../core/services/UtilService";
import {TrialPeriodUpdate} from "../../model/TrialPeriodUpdate";
import * as moment from 'moment';
import {MinimumPropertiesUpdate} from "../../model/MinimumPropertiesUpdate";
import {PromotionCodeUpdate} from "../../model/PromotionCodeUpdate";
import {AdminService} from "../../core/services/admin.service";
import {Location} from "@angular/common";
import {IndividualCompanyUpdate} from "../../model/IndividualCompanyUpdate";
import {OnboardingService} from "../../core/services/onboarding.service";
import {StripeTaxRate} from "../../model/StripeTaxRate";
import {CorporateAndTaxRate} from "../../model/CorporateAndTaxRate";
import {AuthInterceptor} from "../../core/interceptors/auth.interceptors";

@Component({
  selector: 'app-m1-user-details',
  templateUrl: './m1-user-details.component.html',
  styleUrls: ['./m1-user-details.component.scss']
})
export class M1UserDetailsComponent implements OnInit, OnDestroy {

  localPromotionCode: string = '';
  localTrialOverDate: string = '';
  bookingsynchreference: string = '';
  localMinimumProperties: number;
  stripeRateText = '';

  isForceRefresh = false;
  adminCorporateDetailsDto: AdminCorporateDetailsDto = new AdminCorporateDetailsDto();
  onDestroy = new Subject();
  currentStep = 'OTACONNEXIONS';
  corporateId: string;
  dropdownSettings = {};
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  stripeRates : StripeTaxRate[] = [];
  localGiteDeFrancePropertyReference: string = '';
  adminKey: string = '';
  constructor(private userService: UsersService,
              private adminService: AdminService,
              public utilService: UtilService,
              public onBoardingService: OnboardingService,
              private authInterceptor: AuthInterceptor,
              public location: Location,
              private route: ActivatedRoute) { }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
  ngOnInit() {
    this.adminKey =  this.authInterceptor.getAdminKey();
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 1,
      allowSearchFilter: false
    };

    this.route.params.pipe(takeUntil(this.onDestroy)).subscribe(params => {
      if (params.id != null ) {
        this.corporateId = params.id;
        this.refresh(params.id);
      }
    });
    this.userService.getStripeTaxRate().subscribe((res) => {
      console.log("---> TaxRates", res);

      this.stripeRates = res;
      this.stripeRates.forEach( rate => {
        if (rate.active) {
          this.stripeRateText += rate.id + ' (' + rate.description + ') - ';
          this.dropdownList.push({ item_id: rate.id, item_text: rate.description });
        }
      })


    });
  }

  refresh(id: string) {
    this.userService.corporateSearch(id).subscribe( res => {
      console.log('corporate details', res);
      this.adminCorporateDetailsDto = res;
      this.selectedItems = [];
      this.stripeRates.forEach( rate => {
        if (this.adminCorporateDetailsDto.corporate.stripeTaxId === rate.id) {
          this.selectedItems.push({item_id: rate.id, item_text: rate.description});
        }
      });

      this.localMinimumProperties = this.adminCorporateDetailsDto.corporateOwner.stripeMinimumPropertiesNumber;
      this.localPromotionCode = this.adminCorporateDetailsDto.corporateOwner.stripePromotionCode;
    });
  }

  isTrial(user: AdminPersonDetails) {
    /*if (user.hasPaymentMethod) {
      return false;
    }*/
    if (this.utilService.noAbsdifDays(user.trialPeriodOverDate) > 0) {
      return false;
    }
    if (!user.trialPeriodOver) {
      return true;
    }
  }
  isTrialOver(user: AdminPersonDetails) {
    if(user.trialPeriodOver && !user.hasPaymentMethod) {
      return true;
    }
    return false;
  }

  copyClipBoard(person: AdminPersonDetails) {
    navigator.clipboard.writeText(person.email);
  }
  copyClipBoardText(text: string) {
    navigator.clipboard.writeText(text);
  }
  copyClipBoardStaff(person: AdminStaffDetail) {
  navigator.clipboard.writeText(person.email);
  }

  forceRefreshAirbnbAccount(airbnbAccount: AirbnbAccount) {
    this.isForceRefresh = true;
    this.userService.forceAirbnbAccount(airbnbAccount.airbnbEmail, this.adminCorporateDetailsDto.corporateOwner.email).subscribe( res => {
      this.refresh(this.adminCorporateDetailsDto.corporate.id);
      this.isForceRefresh = false;
    });
  }

  setAirbnbInvalid(airbnbAccount: AirbnbAccount) {
    if(confirm("Are you sure to set this Airbnb Account as Invalid?")) {
      this.userService.setAirbnbInvalid(airbnbAccount.id).subscribe( res => {
        this.refresh(this.adminCorporateDetailsDto.corporate.id);
      });
    }
  }

  forceReconnectAirbnbAccount(airbnbAccount: AirbnbAccount) {
    this.userService.reconnectAirbnbAccount(airbnbAccount.airbnbEmail).subscribe( res => {
      this.refresh(this.adminCorporateDetailsDto.corporate.id);
    }, err => {

      alert('Error reconnecting Airbnb Account' + err.error.trace);
    });
  }

  forceRefreshBeds24(beds24Account: Beds24Account) {
    this.isForceRefresh = true;
    this.userService.forceBeds24Account(beds24Account.apiKey).subscribe( res => {
      this.refresh(this.adminCorporateDetailsDto.corporate.id);
      this.isForceRefresh = false;
    });
  }

  forceRefreshSmoobu(smoobuAccount: SmoobuAccount) {
    this.isForceRefresh = true;
    this.userService.forceSmoobuAccount(smoobuAccount.apiKey).subscribe( res => {
      this.refresh(this.adminCorporateDetailsDto.corporate.id);
      this.isForceRefresh = false;
    });
  }


  forceRefreshSuperhote(superhoteAccount: SuperhoteAccount) {
    this.isForceRefresh = true;
    this.userService.forceRefreshSuperhoteAccount(superhoteAccount.email).subscribe( res => {
      this.refresh(this.adminCorporateDetailsDto.corporate.id);
      this.isForceRefresh = false;
    });
  }

  forceRefreshHostify(hostifyAccount: HostifyAccount) {

  }
  forceRefreshIcnea(icneaAccount: IcneaAccount) {

  }
  removeOta(otaType: string, otaAccountEmail: string) {
    if(confirm("Are you sure to remove this Ota " + otaAccountEmail + " account?")) {
      this.adminService.deleteOtaAccount(otaType, otaAccountEmail, this.adminCorporateDetailsDto.corporateOwner.id).subscribe(p => {
        this.refresh(this.adminCorporateDetailsDto.corporate.id);
      });
    }
  }

  forceRefreshBookingDotCom(person: AdminPersonDetails) {
    this.isForceRefresh = true;
    this.userService.forceBookingDotComAccount(this.adminCorporateDetailsDto.corporateOwner.email).subscribe( res => {
      this.refresh(this.adminCorporateDetailsDto.corporate.id);
      this.isForceRefresh = false;
    });
  }

  forceRefreshGiteDeFrance() {
    this.isForceRefresh = true;
    this.userService.forceRefreshGiteDeFrance().subscribe( res => {
      this.refresh(this.adminCorporateDetailsDto.corporate.id);
      this.isForceRefresh = false;
    });
  }

  validateTrialPeriod() {
    if(confirm("Are you sure to change the Trial Period End Date for " + this.adminCorporateDetailsDto.corporateOwner.email + " account?")) {
      const trialPeriodUpdate = new TrialPeriodUpdate();
      trialPeriodUpdate.personId = this.adminCorporateDetailsDto.corporateOwner.id;
      trialPeriodUpdate.trialPeriodDate = moment(this.localTrialOverDate);
      this.userService.updateTrialPeriod(trialPeriodUpdate).subscribe(res => {
        this.adminCorporateDetailsDto.corporateOwner.trialPeriodOverDate = res.trialPeriodOverDate;
      });
    }
  }

  launchXp() {
    if(confirm("Are you sure to force the Launch Guest Experience for " + this.adminCorporateDetailsDto.corporateOwner.email + " account?")) {
      this.isForceRefresh = true;
      this.adminService.setLaunchProcessXp(this.adminCorporateDetailsDto.corporateOwner.id).subscribe(res => {
        this.refresh(this.adminCorporateDetailsDto.corporate.id);
        this.isForceRefresh = false;
      });
    }
  }

  launchTask() {
    if(confirm("Are you sure to force the Launch Tasks for " + this.adminCorporateDetailsDto.corporateOwner.email + " account?")) {
      this.isForceRefresh = true;
      this.adminService.setLaunchProcessTask(this.adminCorporateDetailsDto.corporateOwner.id).subscribe(res => {
        this.refresh(this.adminCorporateDetailsDto.corporate.id);
        this.isForceRefresh = false;
      });
    }
  }

  setAreaOfBusiness(area: string) {
    if(confirm("Are you sure to force the Area of " + area + " for " + this.adminCorporateDetailsDto.corporateOwner.email + " account?")) {
      this.isForceRefresh = true;
      this.adminService.setAreaOfBusiness(this.adminCorporateDetailsDto.corporateOwner.id, area).subscribe(res => {
        this.refresh(this.adminCorporateDetailsDto.corporate.id);
        this.isForceRefresh = false;
      });
    }
  }

  validateEmailVerification() {
    if(confirm("Are you sure to force the email verification for " + this.adminCorporateDetailsDto.corporateOwner.email + " account?")) {
      const trialPeriodUpdate = new TrialPeriodUpdate();
      trialPeriodUpdate.personId = this.adminCorporateDetailsDto.corporateOwner.id;
      trialPeriodUpdate.trialPeriodDate = moment(this.localTrialOverDate);
      this.userService.forceEmailVerification(this.adminCorporateDetailsDto.corporateOwner.email).subscribe(res => {
        this.adminCorporateDetailsDto.corporateOwner.trialPeriodOverDate = res.trialPeriodOverDate;
      });
    }
  }

  forcePremium() {
    if(confirm("Are you sure to force the premium subscription plan for " + this.adminCorporateDetailsDto.corporateOwner.email + " account?")) {
      this.userService.forcePremium(this.adminCorporateDetailsDto.corporateOwner.email).subscribe(res => {
        this.adminCorporateDetailsDto.corporateOwner.trialPeriodOverDate = res.trialPeriodOverDate;
      });
    }
  }

  removeUnpaid() {
    if(confirm("Are you sure to remove the unpaid for " + this.adminCorporateDetailsDto.corporateOwner.email + " account?")) {
      this.userService.removeUnpaid(this.adminCorporateDetailsDto.corporateOwner.email).subscribe(res => {
        this.adminCorporateDetailsDto.corporateOwner.trialPeriodOverDate = res.trialPeriodOverDate;
      });
    }
  }

  forceUnpaid() {
    if(confirm("Are you sure to force unpaid for " + this.adminCorporateDetailsDto.corporateOwner.email + " account?")) {
      this.userService.forceUnPaid(this.adminCorporateDetailsDto.corporateOwner.email).subscribe(res => {
        this.adminCorporateDetailsDto.corporateOwner.trialPeriodOverDate = res.trialPeriodOverDate;
      });
    }
  }

  forcePerformance() {
    if(confirm("Are you sure to force the performance subscription plan for " + this.adminCorporateDetailsDto.corporateOwner.email + " account?")) {
      this.userService.forcePerformance(this.adminCorporateDetailsDto.corporateOwner.email).subscribe(res => {
        this.adminCorporateDetailsDto.corporateOwner.trialPeriodOverDate = res.trialPeriodOverDate;
      });
    }
  }

  validateMinimum() {
    if(confirm("Are you sure to change the minimium billed Properties for " + this.adminCorporateDetailsDto.corporateOwner.email + " account?")) {
      const minimumPropertiesUpdate = new MinimumPropertiesUpdate();
      minimumPropertiesUpdate.personId = this.adminCorporateDetailsDto.corporateOwner.id;
      minimumPropertiesUpdate.minimumProperties = Number(this.localMinimumProperties);
      this.userService.updateMinimumProperties(minimumPropertiesUpdate).subscribe(res => {
        this.adminCorporateDetailsDto.corporateOwner.stripeMinimumPropertiesNumber = res.stripeMinimumPropertiesNumber;
        this.localMinimumProperties = this.adminCorporateDetailsDto.corporateOwner.stripeMinimumPropertiesNumber;
      });
    }
  }

  propagateNormal() {
    if(confirm("Are you sure you want to replace and propagate the normal templates to all properties in this account ?")) {
      this.onBoardingService.onBoardingNormal(this.corporateId).subscribe(res => {
        alert('Onboarding Normal has been propagated');
      });
    }
  }

  validatePromotionCode() {
    if(confirm("Are you sure to change the Promotion Code for " + this.adminCorporateDetailsDto.corporateOwner.email + " account?")) {
      const promotionCodeUpdate = new PromotionCodeUpdate();
      promotionCodeUpdate.personId = this.adminCorporateDetailsDto.corporateOwner.id;
      promotionCodeUpdate.promotionCode = this.localPromotionCode;
      this.userService.updatePromotionCode(promotionCodeUpdate).subscribe(res => {
        this.adminCorporateDetailsDto.corporateOwner.stripePromotionCode = res.stripePromotionCode;
        this.localPromotionCode = this.adminCorporateDetailsDto.corporateOwner.stripePromotionCode;
      });
    }
  }

  changeStep(nextStep: string) {
    if (this.currentStep === nextStep) {
      this.currentStep = '';
    } else {
      this.currentStep = nextStep;
    }
  }

  setAsBeta(person: AdminPersonDetails) {
    if(confirm("Are you sure to change TO Beta " + person.email + " account?")) {
      this.adminService.setAsBeta(person.id).subscribe(p => {
        this.refresh(this.adminCorporateDetailsDto.corporate.id);
      });
    }
  }

  removeAsBeta(person: AdminPersonDetails) {
    if(confirm("Are you sure to change FROM Beta " + person.email + " account?")) {
      this.adminService.removeBeta(person.id).subscribe(p => {
        this.refresh(this.adminCorporateDetailsDto.corporate.id);
      });
    }
  }

  deleteUser(person: AdminPersonDetails) {
    if(confirm("Are you sure to delete " + person.email + " account?")) {
      this.adminService.deleteLogin(person.id).subscribe(() => {
        this.location.back();
        this.refresh(this.adminCorporateDetailsDto.corporate.id);
      });
    }
  }

  closeUser(person: AdminPersonDetails) {
    if(confirm("Are you sure to close " + person.email + " account?")) {
      this.adminService.closeLogin(person.id).subscribe(() => {
        this.refresh(this.adminCorporateDetailsDto.corporate.id);
      });
    }
  }

  uncloseUser(person: AdminPersonDetails) {
    if(confirm("Are you sure to unClose " + person.email + " account?")) {
      this.adminService.unCloseLogin(person.id).subscribe(() => {
        this.refresh(this.adminCorporateDetailsDto.corporate.id);
      });
    }
  }

  forcebookingSyncByReference() {
    if (this.bookingsynchreference != null && this.bookingsynchreference != "") {
      this.userService.forceBookingSyncBufferByReference(this.bookingsynchreference).subscribe((res) => {
        alert(res);
      });
    }
  }

  updateIndividualCompany() {
    const individualCompanyUpdate:  IndividualCompanyUpdate = new IndividualCompanyUpdate();
    individualCompanyUpdate.corporateId = this.corporateId;
    individualCompanyUpdate.individualCompany = this.adminCorporateDetailsDto.corporate.individualCompany;
    this.userService.updateCorporateIndividualCompany(individualCompanyUpdate).subscribe((res) => {
      this.refresh(this.corporateId);
    });
  }

  manageAirbnbSwikly() {
    this.userService.updateAirbnbSwikly(!this.adminCorporateDetailsDto.corporate.swiklyAirbnbAllowed, this.adminCorporateDetailsDto.corporate.id).subscribe((res) => {
      this.refresh(this.adminCorporateDetailsDto.corporate.id);
    });
  }

  onItemSelect(item: any) {
    this.adminCorporateDetailsDto.corporate.stripeTaxId = item.item_id;
  }
  onItemDeSelect(item: any) {
    this.adminCorporateDetailsDto.corporate.stripeTaxId = null;
  }
  findTaxDescription(): string {
    let retDescription = 'Unable to find the Tax Description';
    this.stripeRates.forEach( r => {
      if (r.id === this.adminCorporateDetailsDto.corporate.stripeTaxId) {
        retDescription = r.description;
      }
    });
    return retDescription;

  }
  validateTaxId() {
    const corporateAndTaxRate: CorporateAndTaxRate = new CorporateAndTaxRate();
    corporateAndTaxRate.stripeTaxId = this.adminCorporateDetailsDto.corporate.stripeTaxId;
    corporateAndTaxRate.corporateId = this.adminCorporateDetailsDto.corporate.id;
    this.userService.updateCorporateRate(corporateAndTaxRate).subscribe((res) => {
      this.refresh(this.adminCorporateDetailsDto.corporate.id);
    });
  }

  removeGiteProperty(accountId: string) {
    if (this.localGiteDeFrancePropertyReference != null && this.localGiteDeFrancePropertyReference !== "") {
      this.userService.removePropertyGiteDeFrance(accountId, this.localGiteDeFrancePropertyReference).subscribe( res => {
        this.refresh(this.adminCorporateDetailsDto.corporate.id);
        this.localGiteDeFrancePropertyReference = '';
      });
    }
  }
  addGiteProperty(accountId: string) {
    if (this.localGiteDeFrancePropertyReference != null && this.localGiteDeFrancePropertyReference !== "") {
      this.userService.addPropertyGiteDeFrance(accountId, this.localGiteDeFrancePropertyReference).subscribe( res => {
        this.refresh(this.adminCorporateDetailsDto.corporate.id);
        this.localGiteDeFrancePropertyReference = '';
      });
    }
  }

}
