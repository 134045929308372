import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PermissionsComponent } from './pages/permissions/permissions.component';
import { TagsComponent } from './pages/tags/tags.component';
import {RolesComponent} from "./pages/roles/roles.component";
import { AppComponent } from './app.component';
import {PropertyMapComponent} from "./pages/property-map/property-map.component";
import {M0UsersListComponent} from "./pages/m0-users-list/m0-users-list.component";
import {M1UserDetailsComponent} from "./pages/m1-user-details/m1-user-details.component";
import {M3HomeStatsComponent} from "./pages/m3-home-stats/m3-home-stats.component";
import {M4YaagoPerfComponent} from "./pages/m4-yaago-perf/m4-yaago-perf.component";
import {M5OtaAccountsComponent} from "./pages/m5-ota-accounts/m5-ota-accounts.component";
import {M6TranslationComponent} from "./pages/m6-translation/m6-translation.component";
import {M7AmbassadorComponent} from "./pages/m7-ambassador/m7-ambassador.component";
import {M8AirbnbErrorComponent} from "./pages/m8-airbnb-error/m8-airbnb-error.component";
import {M9DashboardTechComponent} from "./pages/m9-dashboard-tech/m9-dashboard-tech.component";
import {M10YaagoAcademyComponent} from "./pages/m10-yaago-academy/m10-yaago-academy.component";
import {M11YaagoPmsRevComponent} from "./pages/m11-yaago-pms-rev/m11-yaago-pms-rev.component";


const routes: Routes = [
  /*{
    path: 'v2',
    loadChildren: () => import('./appv2/app-v2.module').then(m => m.AppV2Module),
    canActivate : [AuthGuard],

    // pathMatch: 'full',
  },*/
  // {
  //   path: '',
  //   redirectTo: '/v2',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    component: M0UsersListComponent
    //redirectTo: '/dashboard',
    //pathMatch: 'full'

  },
  {
    path: 'dashboard',
    component:AppComponent
  },
  {
    path: 'stats',
    component: M3HomeStatsComponent
  },
  {
    path: "tags",
    component:TagsComponent
  },
  {
    path: "map",
    component:PropertyMapComponent
  },
  {
    path: "permissions",
    component:PermissionsComponent
  },
  {
    path: "roles",
    component:RolesComponent
  },
  {
    path: 'users-list',
    component: M0UsersListComponent
  },
  {
    path: 'yaago-perf',
    component: M4YaagoPerfComponent
  },
  {
    path: 'yaago-ota',
    component: M5OtaAccountsComponent
  },
  {
    path: 'yaago-translate',
    component: M6TranslationComponent
  },
  {
    path: 'yaago-refer',
    component: M7AmbassadorComponent
  },
  {
    path: 'yaago-dash',
    component: M9DashboardTechComponent
  },
  {
    path: 'yaago-academy',
    component: M10YaagoAcademyComponent
  },
  {
    path: 'yaago-pms',
    component: M11YaagoPmsRevComponent
  },
  {
    path: 'yaago-airbnb-error',
    component: M8AirbnbErrorComponent
  },
  {
    path: 'user-details/:id',
    component: M1UserDetailsComponent
  },
  {
    path: '**',
    redirectTo: '/users-list',
    pathMatch: 'full'
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
