<div class="y3-margin-top-24 y3-padding-left-16 y3-padding-right-16">

  <div class="y3-flex-row y3-flex-align-center " >
    <div class="flex-column y3-flex-align-center">
      <div class="m8-account-number">{{airbnbAccounts_NewAccount?.length}}</div>
      <div class="y3-margin-top-4" style="color: green">NEW ACCOUNT(S)</div>
    </div>

    <div class="flex-column y3-flex-align-center y3-margin-left-12">
      <div class="m8-account-number" style="color: red">{{airbnbAccounts?.length}}</div>
      <div class="y3-margin-top-4" style="color: red">INVALID ACCOUNT(S)</div>
    </div>

    <div class="flex-column y3-flex-align-center y3-margin-left-12">
      <div class="m8-account-number" style="color: darkorange">{{yaagoAirbnbCorporateOwnerErrorList?.length}}</div>
      <div class="y3-margin-top-4" style="color: darkorange">MSG ERROR ACCOUNT(S)</div>
    </div>

    <div class="flex-column y3-flex-align-center y3-margin-left-12">
      <div class="m8-account-number" style="color: darkred">{{airbnbAccounts_NotFromServer?.length}}</div>
      <div class="y3-margin-top-4" style="color: darkred">NOTSERVER ACCOUNT(S)</div>
    </div>

    <div class="flex-column y3-flex-align-center y3-margin-left-12">
      <div class="m8-account-number" style="color: green">{{airbnbAccounts_FromServer?.length}}</div>
      <div class="y3-margin-top-4" style="color: green">FROM SERVER ACCOUNT(S)</div>
    </div>

  </div>

  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: black">AIRBNB ACCOUNTS WITH STATUS NEW_ACCOUNT ({{airbnbAccounts_NewAccount.length}} Accounts)</div>

  <table  style="margin-top: 8px; text-align: left">
    <tr>
      <th style="text-align: left">Reconnexion Date </th>
      <th style="text-align: left">FromServer</th>
      <th style="text-align: left">Airbnb Email</th>
      <th style="text-align: left">Airbnb Password</th>
      <th style="text-align: left">Token Status</th>
      <th style="text-align: left">CorporateId</th>
    </tr>
    <tr *ngFor="let otaAccount of airbnbAccounts_NewAccount">
      <td style="text-align: left">{{utilService.formatDateTime(otaAccount.lastReconnectionDateTime)}}</td>
      <td style="text-align: left">{{otaAccount.fromServer}}
        <!--<a href="{{'/#/user-details/' + yaagoError.corporateId}}">{{yaagoError.corporateOwnerEmail}}</a>-->
      </td>
      <td style="text-align: left">{{otaAccount.airbnbEmail}}</td>
      <td style="text-align: left">{{otaAccount.airbnbPassword}}</td>
      <td style="text-align: left">{{otaAccount.otaTokenStatus}}</td>
      <td style="text-align: left">
        <a href="{{'/#/user-details/' + otaAccount.corporateId}}">{{otaAccount.personEmail}}</a>
      </td>
    </tr>
  </table>


  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: black">AIRBNB INVALID FOR MESSAGES</div>

  <table  style="margin-top: 8px; text-align: left">
    <tr>
      <th style="text-align: left">Creation Date </th>
      <th style="text-align: left">Corporate Email</th>
      <th style="text-align: left">Airbnb Email</th>
      <th style="text-align: left">Error</th>
    </tr>
    <tr *ngFor="let yaagoError of yaagoAirbnbCorporateOwnerErrorList">
      <td style="text-align: left">{{utilService.formatDateOnly(yaagoError.createdAt)}}</td>
      <td style="text-align: left">
        <a href="{{'/#/user-details/' + yaagoError.corporateId}}">{{yaagoError.corporateOwnerEmail}}</a>
      </td>
      <td style="text-align: left">{{yaagoError.airbnbEmail}}</td>
      <td style="text-align: left">{{yaagoError.airbnbError}}</td>

    </tr>
  </table>

  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: black">AIRBNB ACCOUNTS WITH STATUS INVALID ({{airbnbAccounts.length}} Accounts)</div>

  <table  style="margin-top: 8px; text-align: left">
    <tr>
      <th style="text-align: left">Reconnexion Date </th>
      <th style="text-align: left">FromServer</th>
      <th style="text-align: left">Airbnb Email</th>
      <th style="text-align: left">Airbnb Password</th>
      <th style="text-align: left">Token Status</th>
      <th style="text-align: left">CorporateId</th>
    </tr>
    <tr *ngFor="let otaAccount of airbnbAccounts">
      <td style="text-align: left">{{utilService.formatDateTime(otaAccount.lastReconnectionDateTime)}} {{utilService.formatDateTime(otaAccount.lastSyncDatetime)}}</td>
      <td style="text-align: left">{{otaAccount.fromServer}}
        <!--<a href="{{'/#/user-details/' + yaagoError.corporateId}}">{{yaagoError.corporateOwnerEmail}}</a>-->
      </td>
      <td style="text-align: left">{{otaAccount.airbnbEmail}}</td>
      <td style="text-align: left">{{otaAccount.airbnbPassword}}</td>
      <td style="text-align: left">{{otaAccount.otaTokenStatus}}</td>
      <td style="text-align: left">
        <a href="{{'/#/user-details/' + otaAccount.corporateId}}">{{otaAccount.personEmail}}</a>
      </td>
    </tr>
  </table>

  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: black">AIRBNB ACCOUNTS NOT FROM SERVER ({{airbnbAccounts_NotFromServer.length}} Accounts)</div>

  <table  style="margin-top: 8px; text-align: left">
    <tr>
      <th style="text-align: left">Reconnexion Date </th>
      <th style="text-align: left">FromServer</th>
      <th style="text-align: left">Airbnb Email</th>
      <th style="text-align: left">Airbnb Password</th>
      <th style="text-align: left">Token Status</th>
      <th style="text-align: left">CorporateId</th>
    </tr>
    <tr *ngFor="let otaAccount of airbnbAccounts_NotFromServer">
      <td style="text-align: left">{{utilService.formatDateTime(otaAccount.lastReconnectionDateTime)}}</td>
      <td style="text-align: left">{{otaAccount.fromServer}}
        <!--<a href="{{'/#/user-details/' + yaagoError.corporateId}}">{{yaagoError.corporateOwnerEmail}}</a>-->
      </td>
      <td style="text-align: left">{{otaAccount.airbnbEmail}}</td>
      <td style="text-align: left">{{otaAccount.airbnbPassword}}</td>
      <td style="text-align: left">{{otaAccount.otaTokenStatus}}</td>
      <td style="text-align: left">
        <a href="{{'/#/user-details/' + otaAccount.corporateId}}">{{otaAccount.personEmail}}</a>
      </td>
    </tr>
  </table>


</div>

